<template>
    <front-layout class="page--venues">
        <v-container>
            <header class="main__header page__top-line">
                <h1>Featured Venues</h1>
                <p>These are the best restaurants near you.</p>
            </header>
        </v-container>
        <div class="page__search-form">
            <v-container>
                <v-row class="flex-column flex-sm-row">
                    <v-col>
                        <v-autocomplete
                            v-model="foodType"
                            :items="foodTypes"
                            :loading="foodTypesLoading"
                            :search-input.sync="foodTypeQuery"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            item-text="name"
                            item-value="id"
                            label="Food Types"
                            prepend-inner-icon="mdi-food-fork-drink"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <city-selector
                            return-object
                            v-model="city"
                            dense solo flat
                            clearable
                            hide-details="auto"
                            prepend-inner-icon="mdi-map-marker"
                            class="rounded-0"
                        />
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="venueFeature"
                            :items="venueFeatures"
                            :loading="venueFeaturesLoading"
                            :search-input.sync="venueFeatureQuery"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            item-text="name"
                            item-value="id"
                            label="Features"
                            prepend-inner-icon="mdi-ballot-outline"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="4">
                        <v-text-field
                            v-model="venueName"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            label="Search for Events or Destinations"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <block slug="ad-1" />
        <v-container class="page__content">
            <v-row>
                <v-col v-for="venue in venues" :key="`artist`+venue.id">
                    <venue-card :venue="venue" v-on:showLogin="showLogin = true"/>
                </v-col>
            </v-row>
            <v-row v-if="totalPages">
                <v-col md="6">
                    <v-pagination
                        v-model="page"
                        :length="totalPages"
                    />
                </v-col>
            </v-row>
            <login-sign-up-modal v-if="showLogin" v-on:click:outside="showLogin = false"/>
            <v-row v-else-if="user && !venuesLoading">
                <v-col>
                    <p class="white--text">Didn't find what you were looking for? Add a missing venue and we will
                        contact them.</p>
                    <v-btn @click="showVenueSubmissionModal = true">
                        Add Venue
                    </v-btn>
                </v-col>
                <venue-submission-modal
                    v-if="showVenueSubmissionModal"
                    v-on:click:outside="showVenueSubmissionModal = false"
                />
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Venue from "@/models/Venue";
import VenueCard from "@/components/cards/VenueCard";
import FoodType from "@/models/FoodType";
import VenueFeature from "@/models/VenueFeature";
import LoginSignUpModal from "@/components/modal/LoginSignUpModal";
import VenueSubmissionModal from "@/components/modal/VenueSubmissionModal";
import CitySelector from "@/components/CitySelector";
import City from "@/models/City";
import Block from "@/components/Block";

export default {
    name: "VenuesIndex",
    components: {CitySelector, VenueSubmissionModal, LoginSignUpModal, VenueCard, FrontLayout, Block},
    data: function () {
        return {
            venuesLoading: false,
            venues: [],
            city: null,
            venueName: null,
            foodType: null,
            foodTypes: [],
            foodTypeQuery: '',
            foodTypesLoading: false,
            venueFeature: null,
            venueFeatures: [],
            venueFeatureQuery: '',
            venueFeaturesLoading: false,
            showLogin: false,
            user: this.$auth.user(),
            showVenueSubmissionModal: false,
            page: 1,
            totalPages: null
        }
    },
    async mounted() {
        this.venuesLoading = true
        await this.setPredefined()
        await this.fetchVenues()
        this.venuesLoading = false
    },
    methods: {
        async fetchVenues() {
            let query = Venue.custom('venues/public').page(this.page)

            if (this.city) {
                query = query.where('city_id', this.city.id)
            }

            if (this.venueName) {
                query = query.where('name', this.venueName)
            }

            if (this.foodType) {
                query = query.where('food_type', this.foodType.id)
            }

            if (this.venueFeature) {
                query = query.where('venue_feature', this.venueFeature.id)
            }

            const venues = await query
                .include(['city', 'food_types', 'venue_features'])
                .append(['review_score'])
                .orderBy('paid')
                .get()

            if (!('data' in venues)) {
                this.venues = venues;
                return
            }

            this.venues = venues.data;
            this.totalPages = venues.meta['last_page']
        },

        async setPredefined() {
            if (this.$route.query.name) {
                this.venueName = this.$route.query.name
            }

            if (this.$route.query.cityId) {
                this.city = await City.find(this.$route.query.cityId)
            }
        },
        resetPageAndReload() {
            this.page = 1
            this.fetchVenues()
        }
    },
    watch: {
        city: function (val, oldval) {
            if (val !== oldval) {
                this.resetPageAndReload()
            }
        },
        venueName: function (val, oldval) {
            if (val !== oldval) {
                this.resetPageAndReload()
            }
        },
        foodType: function (val, oldVal) {
            if (val !== oldVal) {
                this.resetPageAndReload()
            }
        },
        foodTypeQuery: async function (val) {
            this.foodTypesLoading = true

            const foodTypes = await FoodType.custom('food-types/public').where('name', val).limit(10).get();
            if (this.foodType) {
                foodTypes.push(this.foodType)
            }
            this.foodTypes = foodTypes

            this.foodTypesLoading = false
        },
        venueFeature: function (val, oldVal) {
            if (val !== oldVal) {
                this.resetPageAndReload()
            }
        },
        page: function (val, oldVal) {
            if (val !== oldVal) {
                this.fetchVenues()
            }
        },
        venueFeatureQuery: async function (val) {
            this.venueFeaturesLoading = true

            const venueFeatures = await VenueFeature.custom('venue-feature/public').where('name', val).limit(10).get();
            if (this.venueFeature) {
                venueFeatures.push(this.venueFeature)
            }
            this.venueFeatures = venueFeatures

            this.venueFeaturesLoading = false
        },
    }
}
</script>

<style scoped>

</style>
