<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            max-width="600px"
            v-on:click:outside="$emit('click:outside')"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add a venue submission</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.name"
                                    label="Name"
                                    v-model="name"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <city-selector
                                    v-model="city_id"
                                    :error-messages="errors.city_id"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.address"
                                    label="Address"
                                    v-model="address"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="createSubmission">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import VenueSubmission from "@/models/VenueSubmission";
import {mapMutations} from "vuex";
import CitySelector from "@/components/CitySelector";

export default {
    name: "VenueSubmissionModal",
    components: {CitySelector},
    data() {
        return {
            dialog: true,
            name: null,
            city_id: null,
            address: null,
            errors: []
        }
    },
    methods: {
        createSubmission() {
            const venueSubmission = new VenueSubmission()
            venueSubmission.name = this.name
            venueSubmission.city_id = this.city_id
            venueSubmission.address = this.address

            venueSubmission
                .save()
                .then(() => {
                    this.$emit('click:outside');
                    this.showSnackBar({color: 'success', timeout: 3000, text: 'Submission sent'})
                })
                .catch(e => this.errors = e.response.data.errors)
        },
        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style scoped>

</style>
